<template>
  <CCol cols="6" xl="6" lg="6" md="12" sm="12">
    <CCard class="h-100 mb-0">
      <CCardHeader>
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{ $t('learn.Courses_added_last_2_weeks') }}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CRow>
          <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0' : !loadingData}">
            <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
            <b-table v-else
                      class="data_table"
                      :data="courses"
                      :striped="true"
                      :hoverable="true"
                      :mobile-cards="true"
                      :paginated="isPaginated"
                      :per-page="perPage"
                      :current-page.sync="currentPage"
                      :pagination-simple="isPaginationSimple"
                      :pagination-position="paginationPosition"
                      :default-sort-direction="defaultSortDirection"
                      :sort-icon="sortIcon"
                      :sort-icon-size="sortIconSize"
                      default-sort="Name">
                                        
              <template slot-scope="props">
                <b-table-column field="image" label="" width="2.5%">
                  <div v-if="props.row.course_image_id && props.from_marketplace === 'N'"
                       class="item_image d-inline-block align-middle"
                       v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course/' + props.row.lms_course_id_external + '/' + props.row.course_image_id + '.jpg' + '/' + clientToken + ')' }">
                  </div>
                  <div v-else-if="props.row.course_image_id && props.from_marketplace === 'Y'"
                       class="item_image d-inline-block align-middle"
                       v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course-mp/' + props.row.lms_course_id_external + '/' + props.row.course_image_id + '.jpg' + '/' + clientToken + ')' }">
                  </div>                  
                  <div v-else class="item_icon d-inline-block align-middle">
                    <i class="fas fa-list"/>               
                  </div>
                </b-table-column>          
                <b-table-column field="course_name" sortable :label="$t('common.Course')">
                  <span><b>{{props.row.course_name}}</b></span>
                  <div class="mt-1 meta">
                    <span>{{props.row.course_category_name}}</span>
                    <span> | {{props.row.nr_of_active_chapters}} {{props.row.nr_of_active_chapters !== 1 ? $t('common.chapters') : $t('common.chapter')}}</span>
                    <span> | {{props.row.duration}} {{props.row.nr_of_courses !== 1 ? $t('common.minutes') : $t('common.minute')}}</span>
                  </div>
                </b-table-column>              
                <b-table-column field="created" sortable :label="$t('common.Created_at')">
                  <span>{{props.row.created | moment("YYYY-MM-DD HH:mm")}}</span>
                </b-table-column>
                <b-table-column field="action" width="10%">
                  <div class="d-flex justify-content-lg-end">
                    <CButton class="d-inline-block m-0"
                             color="primary"
                             @click="openSidebarRight('assign_courses', { lms_course: { lms_course_id_external: props.row.lms_course_id_external, course_name: props.row.course_name }} )">
                      <i class="fas fa-user-plus"/>
                    </CButton>
                  </div>
                </b-table-column>                
              </template>
              <template slot="empty">
                <div class="text-center">
                  <span>{{ $t('learn.No_courses_added_during_period') }}</span>
                </div>
              </template>
            </b-table>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'RecentlyAddedCourses',
  components: {
    loadingSpinner
  },    
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      loadingData: false,
      courses: [],
      dateFrom: new Date(),
      dateTo: new Date(),
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showInactive: false,
      searchEnabled: false
    }
  },
  methods: {
    getRecentlyAddedCourses() {
      // Start the loader
      this.loadingData = true;
      // Format the dates for the API
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")
      // Get the courses      
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/learn/courses/new/' + dateFromAPI + '/' + dateToAPI)
      .then(res => {
        this.courses = res.data.data;
        // Check if the pagination should be activated
        (this.courses.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Close the loader
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    } 
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    // Set the default for dateFrom to today minus 14 days
    this.dateFrom.setDate(this.dateFrom.getDate() - 14);
    // Get the highlights
    this.getRecentlyAddedCourses();   
  }
}
</script>
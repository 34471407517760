<template>
  <CCol cols="6" xl="6" lg="6" md="6" sm="12" class="loyalty employees_points">
    <CCard class="mb-0 h-100">
      <CCardHeader class="pb-0">
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{ $t('loyalty.Current_points_amount') }}
          </CCol>       
        </CRow>         
      </CCardHeader>      
      <CCardBody>
        <CRow v-if="loadingData" class="h-100">
          <CCol cols="12" xl="12" sm="12" class="pt-0 pb-0">
            <div class="d-flex content-center h-100">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
          </CCol>
        </CRow>
        <CRow v-else class="h-100 m-0">
          <CCol cols="12" class="h-100 p-0">
            <div class="h-100 d-flex align-items-center justify-content-center">
              <div class="flex-grow-1 d-flex align-items-center justify-content-center">
                
                <div class="h-100 d-flex align-items-center justify-content-center currency">
                  <img v-if="employeesPointsCurrency === 'points' && environmentTag === 'harryhr'" src="@/assets/img/harry_hr_credit.png"/>
                  <img v-if="employeesPointsCurrency === 'points' && environmentTag === 'employalty'" src="@/assets/img/employalty_credit.png"/>
                  <img v-if="employeesPointsCurrency === 'points' && environmentTag === 'vitaalbegrip'" src="@/assets/img/vitaal_begrip_credit.png"/>
                  <i v-if="employeesPointsCurrency === 'euro'" class="fa-solid fa-euro-sign"></i>
                </div>

                <div class="h-100 pl-2 d-flex align-items-center justify-content-center flex-column">
                  <span class="count d-block">{{this.employeesPointsCurrency === 'points' ? employeesPoints : formatToCurrency(employeesPoints)}}</span>
                  <span>{{this.employeesPointsCurrency === 'points' ? $t('loyalty.Points_earned_by') : $t('loyalty.Euro_earned_by')}} {{employeesTotal}} {{employeesTotal !== 1 ? $t('common.employees') : $t('common.employee')}}</span>
                </div>
              </div>
              <div class="h-100 pointer currency_switch" @click="switchEmployeesPointsCurrency()">
                <div class="h-100 d-flex align-items-center justify-content-center flex-column">
                  <div class="d-flex justify-content-center">
                    <i class="fa-solid fa-repeat"></i>
                  </div>
                  <div class="switch_label">
                    <span><b>{{$t('To')}} {{this.employeesPointsCurrency === 'points' ? 'euro' : 'points'}}</b></span>
                  </div>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'EmployeesPoints',
  components: {
    loadingSpinner
  },
  data() {
    return {
      cdnBaseUrl: null,
      loadingData: false,      
      employeesPoints: 0,
      employeesAmount: 0,
      employeesAmountFormatted: 0,
      employeesTotal: 0,
      employeesPointsCurrency: 'points',
      environmentTag: null
    }
  },
  methods: {
    formatToCurrency(points) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(points/10);
    },    
    getEmployeesPoints() {
      // Start the loader
      this.loadingData = true;
      // Get the points      
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/points/employees')
      .then(res => {
        this.employeesPoints = res.data.data.personal_loyalty_points;
        this.employeesAmount = res.data.data.personal_loyalty_amount;
        this.employeesAmountFormatted = res.data.data.personal_loyalty_amount_formatted;
        this.employeesTotal = res.data.data.total_employees;
        // Close the loader
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    switchEmployeesPointsCurrency() {
      (this.employeesPointsCurrency === 'points') ? this.employeesPointsCurrency = 'euro' : this.employeesPointsCurrency = 'points';
    },    
  },
  mounted: function(){
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    
    this.getEmployeesPoints();
  }
}
</script>
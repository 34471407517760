<template>
  <CCol cols="12" xl="12">
    <CCard class="mb-0 schedule_contact_call notice_card">
      <CCardBody>       
        <div v-if="environmentTag === 'harryhr'" class="d-flex align-items-center">
          <div class="flex-grow-1">
            <span class="d-block mb-1"><b>{{$t('checklist.Welcome_to_harry_hr_loyalty')}}</b></span>
            <span v-html="$t('checklist.Welcome_to_harry_hr_loyalty_message')"></span>            
          </div>
          <div class="pl-3">
            <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openContactCallSchedulePage()">
              <i class="fa-solid fa-calendar-days button_icon"/>              
              <span v-line-clamp="1" class="button_text">{{$t('checklist.Schedule_contact_call')}}</span>
            </CButton>
          </div>
        </div>
        <div v-if="environmentTag === 'employalty'" class="d-flex align-items-center">
          <div class="flex-grow-1">
            <span class="d-block mb-1"><b>{{$t('checklist.Welcome_to_employalty')}}</b></span>
            <span v-html="$t('checklist.Welcome_to_employalty_message')"></span>            
          </div>
          <div class="pl-3">
            <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openContactCallSchedulePage()">
              <i class="fa-solid fa-calendar-days button_icon"/>              
              <span v-line-clamp="1" class="button_text">{{$t('checklist.Schedule_contact_call')}}</span>
            </CButton>
          </div>
        </div>                          
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>

export default {
  name: 'ScheduleContactCall',
  props: ['environmentTag'],
  methods: {
    openContactCallSchedulePage() {
      if(this.environmentTag === 'harryhr') {
        window.open('https://meetings-eu1.hubspot.com/meetings/bas-hagendijk-heijns/onboarding-call', '_blank');
      } else if(this.environmentTag === 'employalty') {
        window.open('https://meetings-eu1.hubspot.com/bas-hagendijk-heijns/employaltyonboardingnl', '_blank');
      }
    }
  }
}
</script>
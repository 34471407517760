<template>
  <div class="w-100 d-flex flex-wrap insights">
    <CCol :cols="showNPS ? 4 : 6" :xl="showNPS ? 4 : 6" :lg="showNPS ? 4 : 6" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('measure.Sentiment_during_last_3_months')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody v-bind:class="{'pt-0' : metrics.sentiment_score}">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <div v-else class="h-100 d-flex flex-column align-items-center justify-content-center">
            <div v-if="metrics.sentiment_score">
              <Grade :grade="metrics.sentiment_score.average_score" :centered="false"/>
              <ReachDigits :nDigit="metrics.sentiment_score.n_digit" :qDigit="metrics.sentiment_score.q_digit" class="mt-1"/>
            </div>
            <div v-else class="text-center">
              <span>{{ $t('measure.not_enough_sentiment_score_data') }}</span>
            </div>
          </div>          
        </CCardBody>
      </CCard>
    </CCol>
    <CCol v-if="showNPS" cols="4" xl="4" lg="4" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('measure.eNPS_during_last_3_months')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody v-bind:class="{'pt-0' : metrics.enps.data_available}">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <NetPromoterScore v-else :enps="metrics.enps"/>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :cols="showNPS ? 4 : 6" :xl="showNPS ? 4 : 6" :lg="showNPS ? 4 : 6" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('measure.Emoji_cloud_during_last_3_months')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody v-bind:class="{'pt-0' : metrics.emoji_cloud.data_available}">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <EmojiCloud v-else :emojiCloud="metrics.emoji_cloud"/>
        </CCardBody>
      </CCard>
    </CCol>        
  </div>  
</template>


<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Grade from '@/components/insights/common/Grade.vue';
import ReachDigits from '@/components/insights/common/ReachDigits.vue';

import NetPromoterScore from '@/components/insights/measure/NetPromoterScore.vue';
import EmojiCloud from '@/components/insights/measure/EmojiCloud.vue';

export default {
  name: 'MeasureMetrics',
  props: ['showNPS'],
  components: {
    loadingSpinner,
    Grade,
    ReachDigits,
    NetPromoterScore,
    EmojiCloud
  }, 
  data() {
    return {
      loadingData: false,
      metrics: {
        sentiment_score: {
          average_score: '0.0',
          n_digit: 0,
          q_digit: 0
        },
        emoji_cloud: { data_available: false },
        enps: { data_available: false }        
      },
      dateFrom: new Date(),
      dateTo: new Date()      
    }
  },
  methods: {
    getMeasureMetrics() {
      // Start the loader
      this.loadingData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")      
      // Get the employee
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/measure/' + dateFromAPI + '/' + dateToAPI)      
      .then(res => {
        this.metrics = res.data.data;
        // Close the loader
        this.loadingData = false;                      
      })
      .catch(err => {
        console.error(err); 
      });       
    }
  },
  mounted: function() {
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() -90);
    // Set the default for dateFrom to today minus 1 year
    // this.dateFrom.setFullYear(this.dateFrom.getFullYear() - 1);    
    // Get the metrics     
    this.getMeasureMetrics();
  }
}
</script>
<template>
  <CCol cols="12" xl="12" lg="12" md="12" sm="12">
    <CCard class="h-100 mb-0">
      <CCardHeader>
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{$t('topics.Topics')}}
          </CCol>       
        </CRow>       
      </CCardHeader>
      <CCardBody class="pt-0" v-bind:class="{'pb-0' : !loadingData}">
        <div v-if="loadingData" class="h-100 d-flex align-items-center">
          <loadingSpinner mode="auto" :content="null"/>
        </div>
        <BarChart v-else :barChartSeries="barChartSeries" xAxisType="category" yAxisType="integer" :companyPrimaryColor="companyPrimaryColor"/>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import BarChart from '@/components/insights/common/BarChart.vue';

export default {
  name: 'TopicsChart',
  components: {
    loadingSpinner,
    BarChart
  }, 
  data() {
    return {
      companyPrimaryColor: null,
      loadingData: false,
      topics: [],  
      barChartSeries: []      
    }
  },
  methods: {
    getTopics() {
      // Start the loader
      this.loadingData = true;
      // Get the topics
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/connect/topics')      
      .then(res => {
        this.topics = res.data.data;
        // Update the chartSeries
        this.barChartSeries = [
          { name: this.$t('connect.Posts_in_topic_last_month'), data: this.topics.topics_post_count }, 
          { name: this.$t('connect.Authors_last_month'), data: this.topics.topics_post_authors }
        ];
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');          
          // Close the loader
        this.loadingData = false;                      
      })
      .catch(err => {
        console.error(err); 
      });       
    },
  },
  mounted: function() {
    this.getTopics();
  }
}
</script>
<template>
  <apexchart width="100%"
             height="250px"
             :options="chartOptions"
             :series="barChartSeries">
  </apexchart>
</template>

<script>

export default {
  name: 'BarChart',
  props: ['barChartSeries', 'xAxisType', 'yAxisType', 'companyPrimaryColor'],
  watch: {
    $props: {
      handler() {
        this.chartOptions.theme.monochrome.color = this.companyPrimaryColor;
        // Enable the legend if necessary
        if(this.barChartSeries.length > 0) this.chartOptions.legend.show = true;
        // Set the type of the x axis
        this.chartOptions.xaxis.type = this.xAxisType;
        
        if(this.xAxisType === 'datetime') {
          this.chartOptions.tooltip.x.format = 'yyyy-MM-dd';
        }

        if(this.yAxisType === 'decimal') {
          this.chartOptions.yaxis.labels.formatter = function(val) {
            return val.toFixed(1)
          }
        } else {
          this.chartOptions.yaxis.labels.formatter = function(val) {
            return val.toFixed(0)
          }
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          width: '100%',
          animations: {
            initialAnimation: { enabled: true },
            speed: 200
          }
        },
        theme: { 
          monochrome: { enabled: true }
        },
        legend: { show: false },              
        zoom: { autoScaleYaxis: true },
        dataLabels: { enabled: false },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: false,
            columnWidth: '50%'
          }
        }, 
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent']
        },               
        xaxis: {
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'QuickSand, sans-serif',
              fontWeight: 400
            }
          }
        },
        yaxis: {
          tickAmount: 1,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'QuickSand, sans-serif',
              fontWeight: 400
            }                 
          }
        },
        tooltip: {
          x: { show: true }
        }        
      }    
    }
  }
}
</script>

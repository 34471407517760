<template>
  <div class="h-100 d-flex align-items-center justify-content-center employee_service_time">
    <div v-if="showYears" class="years">
      <span class="value mr-1">{{serviceTime.years}}</span>
      <span class="unit">{{$t('common.service_time_years')}}</span>
    </div>
    <div v-if="showMonths" class="months ml-3">
      <span class="value mr-1">{{serviceTime.months}}</span>
      <span class="unit">{{$t('common.service_time_months')}}</span>
    </div>
    <div v-if="showDays" class="days ml-3">
      <span class="value mr-1">{{serviceTime.days}}</span>
      <span class="unit">{{$t('common.service_time_days')}}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ServiceTime',
  props: ['serviceTime', 'showYears', 'showMonths', 'showDays'] 
}
</script>

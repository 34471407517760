<template>
  <CCol cols="12" xl="12" lg="12" md="12" sm="12">
    <CCard class="h-100 mb-0 insights">
      <CCardHeader>
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{$t('insights.Overview_onboarding')}}
          </CCol>       
        </CRow>       
      </CCardHeader>
      <CCardBody class="p-0">
        <CRow v-if="loadingData">
          <CCol cols="12" lg="12">
            <loadingSpinner mode="auto" :content="$t('common.Loading')"/>          
          </CCol>
        </CRow>        
        <OnboardingTable v-else :employees="employees"/>
      </CCardBody>
    </CCard>
  </CCol>
</template>


<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import OnboardingTable from '@/components/insights/learn/OnboardingTable.vue';

export default {
  name: 'Onboarding',
  components: {
    loadingSpinner,
    OnboardingTable
  }, 
  data() {
    return {
      loadingData: false,
      employees: [],
      dateFrom: new Date()   
    }
  },
  methods: {
    getOnboardingEmployees() {
      // Start the loader
      this.loadingData = true;
      // Format the date for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      // Get the employees
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/learn/onboarding/' + dateFromAPI)               
      .then(res => {
        this.employees = res.data.data.onboarding_employees;
          // Close the loader
        this.loadingData = false;                     
      })
      .catch(err => {
        console.error(err); 
      });       
    }
  },
  mounted: function() {       
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() - 90);
    // Get the employees    
    this.getOnboardingEmployees();
  }
}
</script>
<template>
  <CCol cols="6" xl="6" lg="6" md="6" sm="12" class="latest posts">
    <CCard class="mb-0 h-100">
      <CCardHeader class="pb-0">
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{ $t('loyalty.Most_ordered_products') }}
          </CCol>       
        </CRow>         
      </CCardHeader>
      <CCardBody class="has_tabs pb-0">
        <CRow>     
          <CCol cols="12" class="p-0">
            <b-tabs class="most_viewed_posts no_borders mb-0" type="is-boxed" :animated="false" @input="getMostOrderedItems" v-bind:class="{'remove_bottom_content_padding' : !isPaginated && products.length > 0}">
              <b-tab-item>              
                <template slot="header">
                  <span>{{ $t('common.last_30_days')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" v-bind:class="{'p-0' : !loadingData}">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="products"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :default-sort-direction="defaultSortDirection"
                               :sort-icon="sortIcon"
                               :sort-icon-size="sortIconSize"
                               default-sort="orders_amount">                               

                        <template slot-scope="props">
                          <b-table-column field="product_name" sortable :label="$t('common.Product')" :searchable="searchEnabled" width="40%">
                            <div class="d-flex align-items-center">
                              <div v-if="props.row.product_image_id && props.row.from_marketplace === 'N'"
                                  class="store_product_image item_image d-inline-block align-middle mr-2"
                                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                              </div>
                              <div v-else-if="props.row.product_image_id && props.row.from_marketplace === 'Y'"
                                  class="store_product_image item_image d-inline-block align-middle mr-2"
                                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product-mp/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                              </div>                    
                              <div v-else-if="props.row.loyalty_store_partner_product_external_id"
                                  class="item_image d-inline-block align-middle mr-2"
                                  v-bind:style="{ backgroundImage: 'url(' + props.row.loyalty_store_partner_product_image_url + ')' }">
                              </div>
                              <div v-else class="store_product_icon item_icon d-inline-block align-middle mr-2"> 
                                <i class="fas fa-box"/>
                              </div>
                              <div>
                                <span>{{props.row.product_name}}</span>
                                <span class="d-block mt-1 meta" v-html="props.row.variation_name"></span>
                              </div>                        
                            </div>                            
                          </b-table-column>
                          <b-table-column field="loyalty_store_category_name" sortable :label="$t('common.Category')" width="30%">
                            <span>{{ props.row.loyalty_store_category_name }}</span>
                          </b-table-column>
                          <b-table-column field="user_points_spent" sortable :label="$t('insights.Points_spent')" width="25%">
                            <div class="d-flex align-items-center credits">
                              <img v-if="environmentTag === 'harryhr'" src="@/assets/img/harry_hr_credit.png"/>
                              <img v-if="environmentTag === 'employalty'" src="@/assets/img/employalty_credit.png"/>
                              <img v-if="environmentTag === 'vitaalbegrip'" src="@/assets/img/vitaal_begrip_credit.png"/>
                              <span>{{props.row.user_points_spent}} {{props.row.user_points_spent !== 1 ? $t('common.points') : $t('common.point')}}</span>
                            </div>                            
                          </b-table-column>
                          <b-table-column field="orders_amount" sortable :label="$t('insights.Nr_or_orders')" width="15%">
                            <span>{{ props.row.orders_amount }}</span>
                          </b-table-column>                          
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('loyalty.No_products_ordered_during_last_week') }}</span>
                          </div>
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
              <b-tab-item>              
                <template slot="header">
                  <span>{{ $t('common.last_90_days')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" v-bind:class="{'p-0' : !loadingData}">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="products"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :default-sort-direction="defaultSortDirection"
                               :sort-icon="sortIcon"
                               :sort-icon-size="sortIconSize"
                               default-sort="orders_amount">

                        <template slot-scope="props">
                          <b-table-column field="product_name" sortable :label="$t('common.Product')" :searchable="searchEnabled" width="40%">
                            <div class="d-flex align-items-center">
                              <div v-if="props.row.product_image_id && props.row.from_marketplace === 'N'"
                                  class="store_product_image item_image d-inline-block align-middle mr-2"
                                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                              </div>
                              <div v-else-if="props.row.product_image_id && props.row.from_marketplace === 'Y'"
                                  class="store_product_image item_image d-inline-block align-middle mr-2"
                                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product-mp/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                              </div>                    
                              <div v-else-if="props.row.loyalty_store_partner_product_external_id"
                                  class="item_image d-inline-block align-middle mr-2"
                                  v-bind:style="{ backgroundImage: 'url(' + props.row.loyalty_store_partner_product_image_url + ')' }">
                              </div>
                              <div v-else class="store_product_icon item_icon d-inline-block align-middle mr-2"> 
                                <i class="fas fa-box"/>
                              </div>
                              <div>
                                <span>{{props.row.product_name}}</span>
                                <span class="d-block mt-1 meta" v-html="props.row.variation_name"></span>
                              </div>                        
                            </div>                            
                          </b-table-column>
                          <b-table-column field="loyalty_store_category_name" sortable :label="$t('common.Category')" width="30%">
                            <span>{{ props.row.loyalty_store_category_name }}</span>
                          </b-table-column>
                          <b-table-column field="user_points_spent" sortable :label="$t('insights.Points_spent')" width="25%">
                            <div class="d-flex align-items-center credits">
                              <img v-if="environmentTag === 'harryhr'" src="@/assets/img/harry_hr_credit.png"/>
                              <img v-if="environmentTag === 'employalty'" src="@/assets/img/employalty_credit.png"/>
                              <img v-if="environmentTag === 'vitaalbegrip'" src="@/assets/img/vitaal_begrip_credit.png"/>
                              <span>{{props.row.user_points_spent}} {{props.row.user_points_spent !== 1 ? $t('common.points') : $t('common.point')}}</span>
                            </div>                            
                          </b-table-column>
                          <b-table-column field="orders_amount" sortable :label="$t('insights.Nr_or_orders')" width="15%">
                            <span>{{ props.row.orders_amount }}</span>
                          </b-table-column>                          
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('loyalty.No_products_ordered_during_last_month') }}</span>
                          </div>
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>              
              <b-tab-item>
                <template slot="header">                
                  <span>{{ $t('common.last_365_days')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" v-bind:class="{'p-0' : !loadingData}">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="products"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :default-sort-direction="defaultSortDirection"
                               :sort-icon="sortIcon"
                               :sort-icon-size="sortIconSize"
                               default-sort="orders_amount">

                        <template slot-scope="props">
                          <b-table-column field="product_name" sortable :label="$t('common.Product')" :searchable="searchEnabled" width="40%">
                            <div class="d-flex align-items-center">
                              <div v-if="props.row.product_image_id && props.row.from_marketplace === 'N'"
                                  class="store_product_image item_image d-inline-block align-middle mr-2"
                                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                              </div>
                              <div v-else-if="props.row.product_image_id && props.row.from_marketplace === 'Y'"
                                  class="store_product_image item_image d-inline-block align-middle mr-2"
                                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product-mp/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                              </div>                    
                              <div v-else-if="props.row.loyalty_store_partner_product_external_id"
                                  class="item_image d-inline-block align-middle mr-2"
                                  v-bind:style="{ backgroundImage: 'url(' + props.row.loyalty_store_partner_product_image_url + ')' }">
                              </div>
                              <div v-else class="store_product_icon item_icon d-inline-block align-middle mr-2"> 
                                <i class="fas fa-box"/>
                              </div>
                              <div>
                                <span>{{props.row.product_name}}</span>
                                <span class="d-block mt-1 meta" v-html="props.row.variation_name"></span>
                              </div>                        
                            </div>                            
                          </b-table-column>
                          <b-table-column field="loyalty_store_category_name" sortable :label="$t('common.Category')" width="30%">
                            <span>{{ props.row.loyalty_store_category_name }}</span>
                          </b-table-column>
                          <b-table-column field="user_points_spent" sortable :label="$t('insights.Points_spent')" width="25%">
                            <div class="d-flex align-items-center credits">
                              <img v-if="environmentTag === 'harryhr'" src="@/assets/img/harry_hr_credit.png"/>
                              <img v-if="environmentTag === 'employalty'" src="@/assets/img/employalty_credit.png"/>
                              <img v-if="environmentTag === 'vitaalbegrip'" src="@/assets/img/vitaal_begrip_credit.png"/>
                              <span>{{props.row.user_points_spent}} {{props.row.user_points_spent !== 1 ? $t('common.points') : $t('common.point')}}</span>
                            </div>                            
                          </b-table-column>
                          <b-table-column field="orders_amount" sortable :label="$t('insights.Nr_or_orders')" width="15%">
                            <span>{{ props.row.orders_amount }}</span>
                          </b-table-column>                       
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('loyalty.No_products_ordered_during_last_3_months') }}</span>
                          </div>
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>                                                   
            </b-tabs>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'SocialWallPosts',
  components: {
    loadingSpinner
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      loadingData: true,
      products: [],
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showInactive: false,
      searchEnabled: false,
      dateFrom: new Date(),
      dateTo: new Date()         
    }
  },
  methods: {
    getMostOrderedItems(e) {
      let selectedTabIndex = e;
      // Start the loader
      this.loadingData = true;
      // Reset the dateFrom value
      this.dateFrom = new Date();
      if(selectedTabIndex == 0) {
        this.dateFrom.setDate(this.dateFrom.getDate() - 30);
      } else if(selectedTabIndex == 1) {
        this.dateFrom.setDate(this.dateFrom.getDate() - 90);
      } else if(selectedTabIndex == 2) {
        this.dateFrom.setDate(this.dateFrom.getDate() - 365);
      }    
      // Format the dates for the API   
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")
      // Get the views
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/loyalty/products/ordered/' + dateFromAPI + '/' + dateToAPI)
      .then(res => {
        this.products = res.data.data;
        // Check if the pagination should be activated
        (this.products.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;          
        // Set the pagination to the first page
        this.currentPage = 1;
        // Update the loadingData value
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    }
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    // Set the default for dateFrom to today minus 7 days
    this.dateFrom.setDate(this.dateFrom.getDate() - 7);
    // Format the dates for the API   
    let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
    let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")
    // Get the views
    axios.get(process.env.VUE_APP_API_URL + '/v1/insights/loyalty/products/ordered/' + dateFromAPI + '/' + dateToAPI)
    .then(res => {
      this.products = res.data.data;
      // Check if the pagination should be activated
      (this.products.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;            
      // Update the loadingData value
      this.loadingData = false;
    })
    .catch(err => {
      console.error(err); 
    });
  }
}
</script>
<template>
  <div class="announcements" v-if="announcements.length > 0">
    <b-collapse  class="panel dashboard show mb-0" :open="true">
      <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
        <CRow class="w-100 m-0">
          <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
            <h1 class="dashboard_page_title mb-0">{{$t('dash.announcements')}}</h1>            
            <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
          </CCol>
        </CRow>
      </div>
      <div class="panel-block p-0">
        <CRow v-for="announcement in announcements" v-bind:key="announcement.platform_announcement_id" class="w-100 m-0">
          <CCol cols="12" xl="12">
            <CCard class="mb-0 announcement notice_card">
              <CCardBody>       
                <div class="d-flex align-items-center">
                  <div class="pr-3">
                    <span class="d-block mb-1"><b>{{announcement.title}}</b></span>
                    <span>{{announcement.announcement}}</span>
                  </div>
                  <div v-if="announcement.external_link" class="pr-3">
                    <CButton class="m-0" color="primary" @click="openExternalLink(announcement.external_link)">{{$t('common.Read_more')}}</CButton>
                  </div>
                  <div class="align-self-start">
                    <span class="pointer" @click="markAsRead(announcement.platform_announcement_id)"><i class="fas fa-times"/></span>
                  </div>
                </div>                
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Announcements',
  data() {
    return {
      announcements: []
    }
  },
  methods: {
    getPlatformAnnouncements() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/announcements')
      .then(res => {
        this.announcements = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    markAsRead(id) {
      let announcementId = id;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/announcement/' + announcementId + '/read')
      .then(res => {
        this.getPlatformAnnouncements();
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    openExternalLink(url) {
      window.open(url, '_blank');
    },    
  },
  mounted: function(){
    this.getPlatformAnnouncements();
  }
}
</script>
<template>
  <div class="w-100 d-flex flex-wrap">
    <CCol cols="6" xl="6" lg="6" md="12" sm="12">
      <CCard class="mb-0">
        <CCardHeader v-bind:class="{'pb-0' : best_performing_subcategories.length === 0}">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{ $t('measure.Best_performing_subpillars_during_last_3_months') }}
            </CCol>       
          </CRow>       
        </CCardHeader>        
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0' : !loadingData}">
              <loadingSpinner mode="auto" v-if="loadingData" :content="null"/>
              <b-table v-else
                        class="data_table"
                        :data="best_performing_subcategories"
                        :striped="true"
                        :hoverable="true"
                        :mobile-cards="true"
                        :paginated="isPaginated"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        :pagination-simple="isPaginationSimple"
                        :pagination-position="paginationPosition"
                        :default-sort-direction="defaultSortDirection"
                        :sort-icon="sortIcon"
                        :sort-icon-size="sortIconSize"
                        default-sort="Name">
                                          
                <template slot-scope="props">               
                  <b-table-column field="subcategory" :label="$t('sq.Subpillar')" :searchable="searchEnabled" :sortable="false" width="45%">
                    <b>{{props.row.subcategory}}</b>
                  </b-table-column>
                  <b-table-column field="average_score" :label="$t('common.Score')" :searchable="searchEnabled" :sortable="false" width="15%">
                    <div class="item_score mr-3">
                      <span class="integral">{{ getScoreIntegral(props.row.average_score) }}</span>
                      <span class="decimal">.</span>
                      <span class="fractional">{{ getScoreFracetional(props.row.average_score) }}</span>
                    </div>
                  </b-table-column>
                  <b-table-column field="score_difference" :searchable="searchEnabled" :sortable="false">                  
                    <div class="d-flex align-items-center score" v-bind:class="{increased : props.row.growth_last_week > 0, decreased : props.row.growth_last_week < 0}">
                      <p class="mb-0"><span v-bind:class="{above : props.row.score_difference > 0, below : props.row.score_difference < 0}">{{props.row.score_difference}}</span> {{props.row.score_difference > 0 ? $t('common.above') : $t('common.below')}} {{ $t('sq.target_grade') }} ({{props.row.target}})</p>
                    </div>
                  </b-table-column>
                  <b-table-column field="actions">
                    <div class="d-flex justify-content-lg-end">
                      <CButton color="primary" @click="showSubcategoryInsights(props.row.sq_subcategory_id_external)"><i class="fas fa-chevron-right"/></CButton>
                    </div>
                  </b-table-column>            
                </template>
                <template slot="empty">
                  <div class="text-center">
                    <span>{{ $t('measure.No_highlights_available') }}</span>
                  </div>                
                </template>                
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol cols="6" xl="6" lg="6" md="12" sm="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{ $t('measure.Worst_performing_subpillars_during_last_3_months') }}
            </CCol>       
          </CRow>       
        </CCardHeader>        
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0' : !loadingData}">
              <loadingSpinner mode="auto" v-if="loadingData" :content="null"/>
              <b-table v-else
                        class="data_table"
                        :data="worst_performing_subcategories"
                        :striped="true"
                        :hoverable="true"
                        :mobile-cards="true"
                        :paginated="isPaginated"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        :pagination-simple="isPaginationSimple"
                        :pagination-position="paginationPosition"
                        :default-sort-direction="defaultSortDirection"
                        :sort-icon="sortIcon"
                        :sort-icon-size="sortIconSize"
                        default-sort="Name">
                                          
                <template slot-scope="props">               
                  <b-table-column field="subcategory" :label="$t('sq.Subpillar')" :searchable="searchEnabled" :sortable="false" width="45%">
                    <b>{{props.row.subcategory}}</b>
                  </b-table-column>
                  <b-table-column field="average_score" :label="$t('common.Score')" :searchable="searchEnabled" :sortable="false" width="15%">
                    <div class="item_score mr-3">
                      <span class="integral">{{ getScoreIntegral(props.row.average_score) }}</span>
                      <span class="decimal">.</span>
                      <span class="fractional">{{ getScoreFracetional(props.row.average_score) }}</span>
                    </div>
                  </b-table-column>
                  <b-table-column field="score_difference" :searchable="searchEnabled" :sortable="false">
                    <div class="d-flex align-items-center score" v-bind:class="{increased : props.row.growth_last_week > 0, decreased : props.row.growth_last_week < 0}">
                      <p class="mb-0"><span v-bind:class="{above : props.row.score_difference > 0, below : props.row.score_difference < 0}">{{props.row.score_difference}}</span> {{props.row.score_difference > 0 ? $t('common.above') : $t('common.below')}} {{ $t('sq.target_grade') }} ({{props.row.target}})</p>
                    </div>
                  </b-table-column>
                  <b-table-column field="actions">
                    <div class="d-flex justify-content-lg-end">
                      <CButton color="primary" @click="showSubcategoryInsights(props.row.sq_subcategory_id_external)"><i class="fas fa-chevron-right"/></CButton>
                    </div>
                  </b-table-column>            
                </template>
                <template slot="empty">
                  <div class="text-center">
                    <span>{{ $t('measure.No_highlights_available') }}</span>
                  </div>                
                </template>                
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>    
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';


export default {
  name: 'MeasureHighlights',
  components: {
    loadingSpinner
  },    
  data() {
    return {
      loadingData: false,
      best_performing_subcategories: [],
      worst_performing_subcategories: [],
      dateFrom: new Date(),
      dateTo: new Date(),
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 3,
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showInactive: false,
      searchEnabled: false          
    }
  },
  methods: {
    getMeasureHighlights() {
      // Start the loader
      this.loadingData = true;      
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")
      // Get the highlights      
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/measure/highlights/' + dateFromAPI + '/' + dateToAPI)
      .then(res => {
        this.best_performing_subcategories = res.data.data.best_performing_subcategories;
        this.worst_performing_subcategories = res.data.data.worst_performing_subcategories;
        // Loop through the best_performing_subcategories
        for(var s = 0; s < this.best_performing_subcategories.length; s++) {        
          // Calculate score difference
          let scoreDifference = (this.best_performing_subcategories[s].average_score - parseFloat(this.best_performing_subcategories[s].target)).toFixed(1);
          this.best_performing_subcategories[s].score_difference = parseFloat(scoreDifference);
        }
        // Loop through the worst_performing_subcategories
        for(var s = 0; s < this.worst_performing_subcategories.length; s++) {        
          // Calculate score difference
          let scoreDifference = (this.worst_performing_subcategories[s].average_score - parseFloat(this.worst_performing_subcategories[s].target)).toFixed(1);
          this.worst_performing_subcategories[s].score_difference = parseFloat(scoreDifference);
        }        
        // Close the loader
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getScoreIntegral(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(0, score.indexOf('.'));
      } else {
        score = score.toFixed(1).toString();
        return score.substring(0, score.indexOf('.'));
      }
    },
    getScoreFracetional(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(score.indexOf('.') + 1);
      } else {
        score = score.toFixed(1).toString();
        return score.substring(score.indexOf('.') + 1);
      }
    },    
    showSubcategoryInsights(id) {
      this.$router.push({path: `/insights/measure/subcategory/${id.toString()}`});
    }
  },
  mounted: function(){
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() -90);
    // Set the default for dateFrom to today minus 1 year
    // this.dateFrom.setFullYear(this.dateFrom.getFullYear() - 1);
    // Get the highlights
    this.getMeasureHighlights();   
  }
}
</script>
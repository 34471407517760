<template>
  <CRow>
    <CCol cols="12" lg="12" class="pb-0 pt-0">
      <b-table class="data_table"
              :data="courseData"
              :striped="true"
              :hoverable="true"
              :mobile-cards="true"
              :paginated="isPaginated"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :pagination-position="paginationPosition"
              detailed 
              detail-key="lms_course_id_external" 
              :show-detail-icon="true"                     
              :default-sort-direction="defaultSortDirection"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize"
              default-sort="completed.courses_completed">
        
        <template slot-scope="props">
          <b-table-column field="image" width="2.5%">                
            <div v-if="props.row.course_image_id && props.from_marketplace === 'N'"
                 class="item_image d-inline-block align-middle"
                 v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course/' + props.row.lms_course_id_external + '/' + props.row.course_image_id + '.jpg' + '/' + clientToken +')' }">
            </div>
            <div v-else-if="props.row.course_image_id && props.from_marketplace === 'Y'"
                 class="item_image d-inline-block align-middle"
                 v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course-mp/' + props.row.lms_course_id_external + '/' + props.row.course_image_id + '.jpg' + '/' + clientToken +')' }">
            </div>            
            <div v-else class="item_icon d-inline-block align-middle">
              <i class="fas fa-list"/>               
            </div>
          </b-table-column> 
          <b-table-column field="course_name" sortable :label="$t('lms.Course')" :searchable="searchEnabled" width="40%">
            <span><b>{{props.row.course_name}}</b></span>
            <div class="mt-1 meta">
              <span>{{props.row.course_category_name}}</span>
            </div>            
          </b-table-column>
          <b-table-column field="completed.courses_completed" sortable :label="$t('insights.Completed')" width="20%">
            <div class="d-flex align-items-center count">
              <span><i class="icon top far fa-user"/> {{ props.row.completed.courses_completed }}</span>
            </div>
          </b-table-column>
          <b-table-column field="in_progress.courses_in_progress" sortable :label="$t('insights.In_progress')" width="20%">
            <div class="d-flex align-items-center count">
              <span><i class="icon top far fa-user"/> {{ props.row.in_progress.courses_in_progress }}</span>
            </div>
          </b-table-column>
          <b-table-column field="performance" :label="$t('common.performance')" width="20%">
            <div class="d-flex align-items-center count">
              <span><i class="icon top fas fa-tachometer-alt"/> {{ ((props.row.completed.courses_completed / props.row.headcount.headcount_total)*10).toFixed(1) }}</span>
            </div>
          </b-table-column>          
        </template>
        <template slot="empty">
          <div class="p-2 text-center">
            <span>{{ $t('insights.No_onboarding_employees') }}</span>
          </div>                
        </template>
        <template slot="detail" slot-scope="props">
          <b-table class="data_table" 
                    :data="Object.values(props.row.attendees)"
                    :striped="true" 
                    :hoverable="true" 
                    :mobile-cards="true" 
                    :paginated="false"                                                      
                    default-sort-direction='desc'
                    :sort-icon="sortIcon"
                    :sort-icon-size="sortIconSize"
                    default-sort="percentage_completed">
            
            <template slot-scope="props">
              <b-table-column field="image" width="2.5%">
                <userProfilePopover ref="userProfilePopover" mode="user" :userName="props.row.name" :userProfileImage="props.row.user_profile_image" :userIdExternal="props.row.user_id_external" :senderIdExternal="null" popoverPlacement="right"/>          
              </b-table-column>
              <b-table-column field="user_name" :label="$t('common.Employee')" :searchable="searchEnabled" :sortable="true" width="50%">
                <div class="d-flex flex-column">
                  <span><b>{{props.row.user_name}}</b></span>
                  <div class="mt-1 meta">
                    <span>{{props.row.user_department}} - {{props.row.user_team}}</span>
                  </div>
                </div>                      
              </b-table-column>
              <b-table-column field="course_started_date" :label="$t('insights.Started_on')" :searchable="searchEnabled" :sortable="true" width="25%">
                <span>{{props.row.course_started_date}}</span>
              </b-table-column>
              <b-table-column field="course_finished_date" :label="$t('insights.Finished_on')" :searchable="searchEnabled" :sortable="true" width="25%">
                <span>{{props.row.course_finished_date}}</span>
              </b-table-column>
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <span>{{$t('insights.No_course_attendees')}}</span>
              </div>                
            </template>                
          </b-table>                      
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'CourseTable',
  props: ['courses'],
  components: {
    userProfilePopover
  },
  watch: {
    $props: {
      handler() {
        if(this.courses) {
          this.courseData = Object.values(this.courses);
          // Check if the pagination should be activated
          (this.courseData.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      courseData: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      searchEnabled: false,
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
    }
  },
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },  	
  mounted:function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }
}
</script>

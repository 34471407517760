<template>
  <div class="w-100 d-flex flex-wrap insights">
    <CCol cols="6" xl="6" lg="6" md="12" sm="12">
      <CCard v-resize @resize="onResize" class="h-100 mb-0 employee_activity_funnel">
        <CCardHeader class="pb-1">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0">
              {{$t('organisation.Employee_activity')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0' : !loadingActivityData}">
              <div v-if="loadingActivityData" class="h-100 d-flex align-items-center">
                <loadingSpinner mode="auto" :content="null"/>
              </div>                          
              <vue-funnel-graph v-else-if="!loadingActivityData && funnelWidth"
                                :width="funnelWidth"
                                :height="100"
                                :labels="funnelLabels"                         
                                :values="funnelValues"
                                :colors="funnelColors"
                                :sub-labels="[]"
                                :direction="'horizontal'"                         
                                :gradient-direction="'horizontal'"                         
                                :animated="true"
                                :display-percentage="true"
                                class="w-100">
              </vue-funnel-graph>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol cols="3" xl="3" lg="3" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('organisation.Average_time_of_service')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody class="pt-0">
          <div v-if="loadingEmployeeData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <ServiceTime v-else :serviceTime="employees.average_service_time" :showYears="true" :showMonths="true" :showDays="true"/>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol cols="3" xl="3" lg="3" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('organisation.Employees_started_recently')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody class="pt-0">
          <div v-if="loadingEmployeeData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <Count v-else mode="with_description" :count="employees.recently_started" :countTitle="$t('organisation.Employees_starting_shortly') + ': ' + employees.starting_shortly" :countIcon="null"/>
        </CCardBody>
      </CCard>
    </CCol>          
  </div>  
</template>


<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Count from '@/components/insights/common/Count.vue';
import ServiceTime from '@/components/insights/common/ServiceTime.vue';
import { VueFunnelGraph } from 'vue-funnel-graph-js';

export default {
  name: 'EmployeeMetrics',
  components: {
    loadingSpinner,
    Count,
    ServiceTime,
    VueFunnelGraph
  }, 
  data() {
    return {
      loadingEmployeeData: false,
      loadingActivityData: false,
      employees: {
        total: 0,
        recently_started: 0,
        starting_shortly: 0,
        average_service_time: {
          years: 0,
          months: 0,
          days: 0
        }
      },
      activity: {
        total_employees: 0
      },
      funnelWidth: null,
      funnelColors: ['#cfdfda'], 
      funnelValues: [0 ,0 ,0 ,0],
      funnelLabels: [
        this.$t('organisation.Total_employees'),
        this.$t('organisation.Active_employees_during_last_3_months'),
        this.$t('organisation.Active_employees_during_last_month'),
        this.$t('organisation.Active_employees_during_last_week')
      ]
    }
  },
  methods: {
    onResize(e) {
      this.funnelWidth = e.detail.width;
      this.$forceUpdate();
    },    
    getEmployeeMetrics() {
      // Start the loader
      this.loadingEmployeeData = true;
      // Get the employee
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/employees')      
      .then(res => {
        this.employees = res.data.data;
        // Close the loader
        this.loadingEmployeeData = false;
      })
      .catch(err => {
        console.error(err); 
      });       
    },
    getEmployeeActivity() {
      // Start the loader
      this.loadingActivityData = true;
      // Get the employee
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/employees/activity')      
      .then(res => {
        this.activity = res.data.data;
        // Set the funnelValues
        this.funnelValues = [
          this.activity.total,
          this.activity.active_last_3_months,
          this.activity.active_last_month,
          this.activity.active_last_week
        ];
        // Close the loader
        this.loadingActivityData = false;
      })
      .catch(err => {
        console.error(err); 
      });       
    }   
  },
  mounted: function() {
    this.getEmployeeMetrics();
    this.getEmployeeActivity();
  }
}
</script>
<template>
  <CCol cols="12" xl="12">
    <CCard class="mb-0 notice_card warning">
      <CCardBody>       
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <span class="d-block mb-1"><b>{{$t('payments.Invalid_mandate')}}</b></span>
            <span v-html="$t('payments.Mandate_for_recurring_payments_invalid')"></span>            
          </div>
          <div class="pl-3">
            <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openRecurringPaymentsSetupPage()">
                <i class="fa-solid fa-gear button_icon"/>
                <span v-line-clamp="1" class="button_text">{{$t('payments.Set_up_recurring_payments')}}</span>
            </CButton>
          </div>
        </div>                        
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>

export default {
  name: 'InvalidMandate',  
  methods: {
    openRecurringPaymentsSetupPage() {      
      if(this.$router.currentRoute.path !== '/core/payments/recurring/setup')  this.$router.push({path: '/core/payments/recurring/setup'});
    }
  }
}
</script>
<template>
  <CCol cols="12" xl="12" lg="12" md="12" sm="12">
    <CCard class="mb-0">
      <CCardHeader>
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{ $t('knowledge.Items_added_last_2_weeks') }}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CRow>
          <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0' : !loadingData}">
            <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
            <b-table v-else
                      class="data_table"
                      :data="items"
                      :striped="true"
                      :hoverable="true"
                      :mobile-cards="true"
                      :paginated="isPaginated"
                      :per-page="perPage"
                      :current-page.sync="currentPage"
                      :pagination-simple="isPaginationSimple"
                      :pagination-position="paginationPosition"
                      :default-sort-direction="defaultSortDirection"
                      :sort-icon="sortIcon"
                      :sort-icon-size="sortIconSize"
                      default-sort="Name">
                                        
              <template slot-scope="props">
                <b-table-column field="item_title" sortable :label="$t('common.Title')">
                  <span class="pointer" @click="openSidebarRight('knowledge_item_preview', { kb_intent_id_external: props.row.kb_intent_id_external })"><b>{{props.row.item_title ? props.row.item_title : '-'}}</b></span>
                  <div class="mt-1 meta">
                    <span>{{props.row.kb_intent_category_name}} > {{props.row.kb_intent_subcategory_name}}</span>
                  </div>
                </b-table-column>
                <b-table-column field="created" sortable :label="$t('common.Created_by')">
                  <div class="d-flex align-items-center count">
                    <userProfilePopover ref="userProfilePopover" mode="user" :userName="props.row.author" :userProfileImage="props.row.author_profile_image" :userIdExternal="props.row.author_user_id_external" :senderIdExternal="null" popoverPlacement="right"/>
                    <span class="ml-1">{{props.row.author}} {{$t('at')}} {{props.row.created | moment("YYYY-MM-DD HH:mm")}}</span>
                  </div>
                </b-table-column>
                <b-table-column field="created" sortable :label="$t('common.Last_update_by')">
                  <div v-if="props.row.editor_user_id_external" class="d-flex align-items-center count">
                    <userProfilePopover ref="userProfilePopover" mode="user" :userName="props.row.editor" :userProfileImage="props.row.editor_profile_image" :userIdExternal="props.row.editor_user_id_external" :senderIdExternal="null" popoverPlacement="right"/>
                    <span class="ml-1">{{props.row.editor}} {{$t('at')}} {{props.row.created | moment("YYYY-MM-DD HH:mm")}}</span>
                  </div>
                  <span v-else>-</span>
                </b-table-column>
                <b-table-column field="item_evaluation_date" sortable :label="$t('kb.Evaluation_by')">
                  <div v-if="props.row.evaluator_user_id_external" class="d-flex align-items-center count">
                    <userProfilePopover ref="userProfilePopover" mode="user" :userName="props.row.evaluator" :userProfileImage="props.row.evaluator_profile_image" :userIdExternal="props.row.evaluator_user_id_external" :senderIdExternal="null" popoverPlacement="right"/>
                    <span class="ml-1">{{props.row.evaluator}} {{$t('on')}} {{props.row.item_evaluation_date | moment("YYYY-MM-DD")}}</span>
                  </div>
                  <span v-else>-</span>
                </b-table-column>                             
                <b-table-column field="actions">
                  <div class="d-flex justify-content-lg-end">
                    <CButton class="d-inline-block" color="primary" @click="showItemInsights( props.row.kb_intent_id_external )">
                      <i class="far fa-chart-bar"/>
                    </CButton>
                  </div>
                </b-table-column>                
              </template>
              <template slot="empty">
                <div class="text-center">
                  <span>{{ $t('knowledge.No_items_added_during_period') }}</span>
                </div>
              </template>
            </b-table>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'RecentlyAddedItems',
  components: {
    loadingSpinner,
    userProfilePopover
  },    
  data() {
    return {
      loadingData: false,
      items: [],
      dateFrom: new Date(),
      dateTo: new Date(),
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showInactive: false,
      searchEnabled: false
    }
  },
  methods: {
    getRecentlyAddedItems() {
      // Start the loader
      this.loadingData = true;
      // Format the dates for the API
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")
      // Get the items      
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/knowledge/items/new/' + dateFromAPI + '/' + dateToAPI)
      .then(res => {
        this.items = res.data.data;
        // Check if the pagination should be activated
        (this.items.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Close the loader
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showItemInsights(id) {
      this.$router.push({path: `/insights/knowledge/item/${id.toString()}`});
    }    
  },
  mounted: function(){
    // Set the default for dateFrom to today minus 14 days
    this.dateFrom.setDate(this.dateFrom.getDate() - 14);
    // Get the highlights
    this.getRecentlyAddedItems();   
  }
}
</script>
<template>
  <CCol cols="12" xl="12">
    <CCard v-if="onboardingChecklist" class="mb-0 onboarding_checklist">
      <CCardBody>    
        <div class="onboarding_checklist_items">
          
          <div @click="openLookAndFeelPage()" class="d-flex align-items-center pointer">
            <div class="pr-2">
              <i v-bind:class="{'fa-solid fa-circle-check' : onboardingChecklist.company_look_and_feel_set === true, 'fa-regular fa-circle' : onboardingChecklist.company_look_and_feel_set === false}"></i>
            </div>
            <div class="flex-grow-1">    
              <span><b>{{$t('checklist.Set_look_and_feel_of_platform')}}</b></span>
            </div>
            <div class="pl-3">
              <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openLookAndFeelPage()">
                <i class="fa-solid fa-palette button_icon"/>              
                <span v-line-clamp="1" class="button_text">{{$t('checklist.Set_look_and_feel')}}</span>
              </CButton>               
            </div>                 
          </div>
          
          <div @click="openOrganizationPage()" class="d-flex align-items-center pointer">
            <div class="pr-2">
              <i class="fa-regular" v-bind:class="{'fa-circle-check' : onboardingChecklist.company_employee_count > 1, 'fa-circle' : onboardingChecklist.company_employee_count <= 1}"></i>
            </div>
            <div class="flex-grow-1">
              <span><b>{{$t('checklist.Import_or_add_employees')}}</b></span>
              <span class="d-block meta">{{$t('checklist.Current_company_employee_count')}}: {{onboardingChecklist.company_employee_count}}</span>
            </div>
            <div class="pl-3">
              <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openOrganizationPage()">
                <i class="fa-solid fa-users button_icon"/>              
                <span v-line-clamp="1" class="button_text">{{$t('checklist.Show_employees')}}</span>
              </CButton>               
            </div>                 
          </div>
          
          <div @click="openSystemAutoMomentsPage()" class="d-flex align-items-center pointer">
            <div class="pr-2">
              <i class="fa-regular" v-bind:class="{'fa-circle-check' : onboardingChecklist.company_system_auto_moments_set === true, 'fa-circle' : onboardingChecklist.company_system_auto_moments_set === false}"></i>
            </div>
            <div class="flex-grow-1">
              <span><b>{{$t('checklist.Setup_system_auto_moments')}}</b></span>
            </div>
            <div class="pl-3">
              <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openSystemAutoMomentsPage()">
                <i class="fa-solid fa-calendar-day button_icon"/>              
                <span v-line-clamp="1" class="button_text">{{$t('checklist.Setup_auto_moments')}}</span>
              </CButton>              
            </div>
          </div>
          
          <div v-if="modules.employee_loyalty_programme == 1 && onboardingChecklist.company_financial_details" @click="openPaymentsSetupPage()" class="d-flex align-items-center pointer">
            <div class="pr-2">
              <i v-bind:class="{'fa-solid fa-circle-check' : onboardingChecklist.company_financial_details.details_available === true && onboardingChecklist.company_financial_details.setup_completed === true, 
                                'fa-regular fa-circle-check' : onboardingChecklist.company_financial_details.details_available === true && onboardingChecklist.company_financial_details.setup_completed === false,
                                'fa-regular fa-circle' : onboardingChecklist.company_financial_details.details_available === false && onboardingChecklist.company_financial_details.setup_completed === false}"></i>
            </div>
            <div class="flex-grow-1">
              <span><b>{{$t('checklist.Set_your_financial_details')}}</b></span>
            </div>
            <div class="pl-3">
              <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openPaymentsSetupPage()">
                <i class="fa-solid fa-file-invoice button_icon"/>              
                <span v-line-clamp="1" class="button_text">{{$t('checklist.Set_financial_details')}}</span>
              </CButton>
            </div>
          </div>

          <div v-if="modules.employee_loyalty_programme == 1 && onboardingChecklist.company_financial_details && onboardingChecklist.company_financial_details.setup_completed === true && onboardingChecklist.company_financial_details.payment_type_tag === 'recurring'"
               @click="openRecurringPaymentsSetupPage()" 
               class="d-flex align-items-center pointer">
            <div class="pr-2">
              <i v-bind:class="{'fa-solid fa-circle-check' : onboardingChecklist.company_financial_details.payment_mandate_set, 'fa-regular fa-circle' : !onboardingChecklist.company_financial_details.payment_mandate_set}"></i>
            </div>
            <div class="flex-grow-1">
              <span><b>{{$t('checklist.Set_up_your_recurring_payments')}}</b></span>
            </div>
            <div class="pl-3">
              <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openRecurringPaymentsSetupPage()">
                <i class="fa-solid fa-gear button_icon"/>
                <span v-line-clamp="1" class="button_text">{{$t('checklist.Set_up_recurring_payments')}}</span>
              </CButton>
            </div>
          </div>

          <div v-if="modules.employee_loyalty_programme == 1 && onboardingChecklist.company_financial_details && onboardingChecklist.company_financial_details.setup_completed === true && onboardingChecklist.company_financial_details.payment_type_tag === 'prepaid'"
               @click="openCompanyWalletBalanceTopUpPage()"
               class="d-flex align-items-center pointer">
            <div class="pr-2">
              <i class="fa-regular" v-bind:class="{'fa-circle-check' : onboardingChecklist.company_financial_details.loyalty_wallet_coins > 0, 'fa-circle' : onboardingChecklist.company_financial_details.loyalty_wallet_coins === 0}"></i>
            </div>
            <div class="flex-grow-1">
              <span><b>{{$t('checklist.Top_up_your_wallet_balance')}}</b></span>
              <span class="d-block meta">{{$t('common.Current_wallet_balance')}}: {{onboardingChecklist.company_financial_details.loyalty_wallet_coins}} {{onboardingChecklist.company_financial_details.loyalty_wallet_coins !== 1 ? $t('common.points') : $t('common.point')}}</span>
            </div>
            <div class="pl-3">
              <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openCompanyWalletBalanceTopUpPage()">
                <i class="fa-solid fa-square-up-right button_icon"/>
                <span v-line-clamp="1" class="button_text">{{$t('checklist.Top_up_wallet_balance')}}</span>
              </CButton>
            </div>
          </div>

          <div @click="twoFactorModal = true" class="d-flex align-items-center pointer">
            <div class="pr-2">
              <i v-bind:class="{'fa-solid fa-circle-check' : onboardingChecklist.user_2fa_setup, 'fa-regular fa-circle' : !onboardingChecklist.user_2fa_setup}"></i>
            </div>
            <div class="flex-grow-1">
              <span><b>{{$t('checklist.Setup_2fa_for_dashboard_login')}}</b></span>
            </div>
            <div class="pl-3">
              <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="twoFactorModal = true;">
                <i class="fa-solid fa-lock button_icon"/>              
                <span v-line-clamp="1" class="button_text">{{$t('checklist.Setup_2fa')}}</span>
              </CButton>               
            </div>                 
          </div>
        </div>
      </CCardBody>
    </CCard>

    <b-modal :can-cancel="['x']" :active.sync="twoFactorModal" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <span>{{$t('checklist.Setup_your_2fa')}}</span>
        </CCardHeader>
        <CCardBody>
          <span v-if="!onboardingChecklist.user_2fa_setup">{{$t('checklist.To_setup_your_2fa')}}</span>
          <span v-else>{{$t('checklist.2FA_already_setup')}}</span>
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton v-if="!onboardingChecklist.user_2fa_setup" color="primary" @click="enableTwoFactorAuthentication()"><i class="fa-solid fa-right-from-bracket mr-1"></i>{{$t('checklist.Logout_and_setup_2fa')}}</CButton>
            <CButton color="secondary" @click="twoFactorModal = false"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          </div>
        </CCardFooter>            
      </CCard>
    </b-modal>     
  </CCol>
</template>

<script>
import axios from 'axios'

export default {
  name: 'OnboardingChecklist',
  props: ['checklist', 'modules'],
  watch: {
    $props: {
      handler() {
        if(this.checklist) this.onboardingChecklist = this.checklist;
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      onboardingChecklist: {
        company_financial_details: {
          details_available: false,
          payment_method_set: false,
          payment_customer_set: false,
          payment_mandate_set : false,
          setup_completed: false,
          payment_type_tag: null
        }
      },
      twoFactorModal: false
    }
  },
  methods: {
    openOrganizationPage() {
      // Set organizationViewToActivate item in localStorage
      localStorage.setItem('organizationViewToActivate', 'users');
      // Navigate to organization page
      if(this.$router.currentRoute.path !== '/core/organization') this.$router.push({path: '/core/organization'});
    },
    openLookAndFeelPage() {
      // Set navigatedFromOnboardingChecklist item in localStorage
      localStorage.setItem('navigatedFromOnboardingChecklist', 'Y');
      // Navigate to look and feel page      
      if(this.$router.currentRoute.path !== '/core/look-and-feel') this.$router.push({path: '/core/look-and-feel'});
    },    
    openSystemAutoMomentsPage() {
      // Navigate to system auto moments page
      if(this.$router.currentRoute.path !== '/core/system-communications')  this.$router.push({path: '/core/system-communications'});
    },
    openPaymentsSetupPage() {
      // Navigate to payments setup page
      if(this.$router.currentRoute.path !== '/core/payments/setup')  this.$router.push({path: '/core/payments/setup'});
    },     
    openRecurringPaymentsSetupPage() {
      // Navigate to recurring payments setup page
      if(this.$router.currentRoute.path !== '/core/payments/recurring/setup')  this.$router.push({path: '/core/payments/recurring/setup'});
    },
    openCompanyWalletBalanceTopUpPage() {
      // Navigate to prepaid balance topup page
      if(this.$router.currentRoute.path !== '/core/payments/prepaid/topup')  this.$router.push({path: '/core/payments/prepaid/topup'});      
    },
    enableTwoFactorAuthentication() {
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/authentication/enable')
      .then(res => {
        this.$store.dispatch('logout').then(() => {
          this.$router.push('/login')
        })
      })
      .catch(err => {
        console.error(err); 
      });      
    }
  }  
}
</script> 
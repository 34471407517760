<template>
  <CCol cols="6" xl="6" lg="6" md="12" sm="12" class="latest posts">
    <CCard class="mb-0 h-100">
      <CCardHeader class="pb-0">
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{ $t('knowledge.Most_viewed_items') }}
          </CCol>       
        </CRow>         
      </CCardHeader>
      <CCardBody class="has_tabs pb-0">
        <CRow>     
          <CCol cols="12" class="p-0">
            <b-tabs class="most_viewed_posts no_borders mb-0" type="is-boxed" :animated="false" @input="getMostViewedItems" v-bind:class="{'remove_bottom_content_padding' : !isPaginated && items.length > 0}">
              <b-tab-item>              
                <template slot="header">
                  <span>{{ $t('common.last_week')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" v-bind:class="{'p-0' : !loadingData}">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="items"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition">

                        <template slot-scope="props">
                          <b-table-column field="title" :label="$t('common.Title')" width="65%">
                            <span class="pointer" @click="openSidebarRight('knowledge_item_preview', { kb_intent_id_external: props.row.kb_intent_id_external })"><b>{{props.row.title ? props.row.title : '-'}}</b></span>
                            <div class="mt-1 meta">
                              <span>{{props.row.kb_intent_category_name}} > {{props.row.kb_intent_subcategory_name}}</span>
                            </div>                               
                          </b-table-column>
                          <b-table-column field="views" sortable :label="$t('common.Total_views')" width="15%">
                            <div class="d-flex align-items-center count">
                              <span>{{props.row.views}}</span>
                            </div>
                          </b-table-column> 
                          <b-table-column field="reach" sortable :label="$t('common.Unique_views')" width="15%">
                            <div class="d-flex align-items-center count">
                              <span>{{props.row.reach}}</span>
                            </div>
                          </b-table-column>                           
                          <b-table-column field="actions">
                            <div class="d-flex justify-content-lg-end">                              
                              <CButton class="m-0" color="primary" @click="showItemInsights( props.row.kb_intent_id_external )">
                                <i class="far fa-chart-bar"/>
                              </CButton>
                            </div>
                          </b-table-column>                          
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('knowledge.No_items_viewed_during_last_week') }}</span>
                          </div>
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
              <b-tab-item>              
                <template slot="header">
                  <span>{{ $t('common.last_month')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" v-bind:class="{'p-0' : !loadingData}">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="items"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition">

                        <template slot-scope="props">
                          <b-table-column field="title" :label="$t('common.Title')" width="65%">
                            <span class="pointer" @click="openSidebarRight('knowledge_item_preview', { kb_intent_id_external: props.row.kb_intent_id_external })"><b>{{props.row.title ? props.row.title : '-'}}</b></span>
                            <div class="mt-1 meta">
                              <span>{{props.row.kb_intent_category_name}} > {{props.row.kb_intent_subcategory_name}}</span>
                            </div>                               
                          </b-table-column>
                          <b-table-column field="views" sortable :label="$t('common.Total_views')" width="15%">
                            <div class="d-flex align-items-center count">
                              <span>{{props.row.views}}</span>
                            </div>
                          </b-table-column> 
                          <b-table-column field="reach" sortable :label="$t('common.Unique_views')" width="15%">
                            <div class="d-flex align-items-center count">
                              <span>{{props.row.reach}}</span>
                            </div>
                          </b-table-column> 
                          <b-table-column field="actions">
                            <div class="d-flex justify-content-lg-end">                              
                              <CButton class="m-0" color="primary" @click="showItemInsights( props.row.kb_intent_id_external )">
                                <i class="far fa-chart-bar"/>
                              </CButton>
                            </div>
                          </b-table-column>                          
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('knowledge.No_items_viewed_during_last_month') }}</span>
                          </div>
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>              
              <b-tab-item>
                <template slot="header">                
                  <span>{{ $t('common.last_3_months')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" v-bind:class="{'p-0' : !loadingData}">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="items"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition">

                        <template slot-scope="props">
                          <b-table-column field="title" :label="$t('common.Title')" width="65%">
                            <span class="pointer" @click="openSidebarRight('knowledge_item_preview', { kb_intent_id_external: props.row.kb_intent_id_external })"><b>{{props.row.title ? props.row.title : '-'}}</b></span>
                            <div class="mt-1 meta">
                              <span>{{props.row.kb_intent_category_name}} > {{props.row.kb_intent_subcategory_name}}</span>
                            </div>                               
                          </b-table-column>
                          <b-table-column field="views" sortable :label="$t('common.Total_views')" width="15%">
                            <div class="d-flex align-items-center count">
                              <span>{{props.row.views}}</span>
                            </div>
                          </b-table-column> 
                          <b-table-column field="reach" sortable :label="$t('common.Unique_views')" width="15%">
                            <div class="d-flex align-items-center count">
                              <span>{{props.row.reach}}</span>
                            </div>
                          </b-table-column> 
                          <b-table-column field="actions">
                            <div class="d-flex justify-content-lg-end">                              
                              <CButton class="m-0" color="primary" @click="showItemInsights( props.row.kb_intent_id_external )">
                                <i class="far fa-chart-bar"/>
                              </CButton>
                            </div>
                          </b-table-column>                          
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('knowledge.No_items_viewed_during_last_3_months') }}</span>
                          </div>
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>                                                   
            </b-tabs>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import axios from 'axios'
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'SocialWallPosts',
  components: {
    userProfilePopover,
    loadingSpinner
  },  
  data() {
    return {
      loadingData: false,
      items: [],
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showInactive: false,
      searchEnabled: true,
      dateFrom: new Date(),
      dateTo: new Date()         
    }
  },
  methods: {
    getMostViewedItems(e) {
      let selectedTabIndex = e;
      // Start the loader
      this.loadingData = true;
      // Reset the dateFrom value
      this.dateFrom = new Date();
      if(selectedTabIndex == 0) {
        this.dateFrom.setDate(this.dateFrom.getDate() - 7);
      } else if(selectedTabIndex == 1) {
        this.dateFrom.setDate(this.dateFrom.getDate() - 30);
      } else if(selectedTabIndex == 2) {
        this.dateFrom.setDate(this.dateFrom.getDate() - 90);
      }    
      // Format the dates for the API   
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");
      // Get the views
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/knowledge/items/views/' + dateFromAPI + '/' + dateToAPI)
      .then(res => {
        this.items = res.data.data;
        // Check if the pagination should be activated
        (this.items.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;          
        // Set the pagination to the first page
        this.currentPage = 1;
        // Update the loadingData value
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showItemInsights(id) {
      this.$router.push({path: `/insights/knowledge/item/${id.toString()}`});
    }
  },
  mounted: function(){
    // Set the default for dateFrom to today minus 7 days
    this.dateFrom.setDate(this.dateFrom.getDate() - 7);
    // Format the dates for the API   
    let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
    let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")
    // Get the views
    axios.get(process.env.VUE_APP_API_URL + '/v1/insights/knowledge/items/views/' + dateFromAPI + '/' + dateToAPI)
    .then(res => {
      this.items = res.data.data;
      // Check if the pagination should be activated
      (this.items.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;            
      // Update the loadingData value
      this.loadingData = false;
    })
    .catch(err => {
      console.error(err); 
    });
  }
}
</script>
<template>
  <CCol cols="6" xl="6" lg="6" md="12" sm="12">
    <CCard class="h-100 mb-0">
      <CCardHeader>
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{$t('learn.Course_performance_last_3_months')}}
          </CCol>       
        </CRow>       
      </CCardHeader>
      <CCardBody class="p-0">
        <CRow v-if="loadingData">
          <CCol cols="12" lg="12">
            <loadingSpinner mode="auto" :content="$t('common.Loading')"/>          
          </CCol>
        </CRow>        
        <CourseTable v-else :courses="courses"/>
      </CCardBody>
    </CCard>
  </CCol>
</template>


<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import CourseTable from '@/components/insights/learn/CourseTable.vue';

export default {
  name: 'CoursePerformance',
  components: {
    loadingSpinner,
    CourseTable
  }, 
  data() {
    return {
      loadingData: false,
      courses: [],
      dateFrom: new Date(),
      dateTo: new Date()      
    }
  },
  methods: {
    getCourses() {
      // Start the loader
      this.loadingData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");
      // Get the courses
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/learn/courses/' + dateFromAPI + '/' + dateToAPI)               
      .then(res => {
        this.courses = res.data.data;
          // Close the loader
        this.loadingData = false;                     
      })
      .catch(err => {
        console.error(err); 
      });       
    }
  },
  mounted: function() {       
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() - 90);
    // Get the courses    
    this.getCourses();
  }
}
</script>
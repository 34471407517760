<template>
  <div class="w-100 d-flex flex-wrap insights">
    <CCol cols="4" xl="4" lg="4" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('connect.Posts_published_this_week')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody class="pt-0">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <Count v-else mode="single_number" :count="metrics.posts_published_last_week" :countTitle="null" :countIcon="null"/>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol cols="4" xl="4" lg="4" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('connect.Posts_scheduled_this_week')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody class="pt-0">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <Count v-else mode="single_number" :count="metrics.posts_scheduled_upcoming_week" :countTitle="null" :countIcon="null"/>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol cols="4" xl="4" lg="4" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('connect.Average_posts_seen_by_employees')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody class="pt-0">
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <Count v-else mode="single_number" :count="views.total_post_views > 0 || views.total_employees > 0 ? (views.total_post_views / views.total_employees).toFixed(0) : 0" :countTitle="null" :countIcon="null"/>
        </CCardBody>
      </CCard>
    </CCol>        
  </div>  
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Count from '@/components/insights/common/Count.vue';

export default {
  name: 'EmployeeMetrics',
  components: {
    loadingSpinner,
    Count
  }, 
  data() {
    return {
      loadingData: false,
      loadingViewsData: false,
      metrics: {
        posts_published_last_week: 0,
        posts_scheduled_upcoming_week: 0
      },
      views: {
        total_employees: 0,
        posts_viewed_by_employees: 0
      },
      dateFrom: new Date(),
      dateTo: new Date(),      
    }
  },
  methods: {
    getConnectMetrics() {
      // Start the loader
      this.loadingData = true;
      // Get the employee
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/connect')      
      .then(res => {
        this.metrics = res.data.data;
          // Close the loader
        this.loadingData = false;                      
      })
      .catch(err => {
        console.error(err); 
      });       
    },
    getConnectViews() {
      // Start the loader
      this.loadingViewsData = true;      
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")
      // Get the highlights      
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/connect/views/' + dateFromAPI + '/' + dateToAPI)
      .then(res => {
        this.views = res.data.data;
        // Close the loader
        this.loadingViewsData = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    }
  },
  mounted: function() {
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() -90);
    // Set the default for dateFrom to today minus 1 year
    // this.dateFrom.setFullYear(this.dateFrom.getFullYear() - 1);    
    // Get the metrics
    this.getConnectMetrics();
    // Get the views
    this.getConnectViews();
  }
}
</script>